<section class="footer-section row">
    <div class="col-3">
        <p>ABOUT US</p>
        <p class="text-primary">Mechtron Group of Company is renowned as a leading company providing Waste Water  Solutions &  Calibration Services of Electro-Mechanical Items.Our Services are described briefly as below.</p>
    </div>
    <div class="col-3">
        <p>QUICK LINKS</p>
        <!-- <ul>
            <li><a title="Mechanical" href="#">Mechanical Calibration</a></li>
            <li><a title="Electro Technical" href="#">Electro Technical Calibration</a></li>
            <li><a title="Thermal" href="#">Thermal Calibration</a></li>
            <li><a title="Validation Equipment" href="#">Validation Equipment</a></li>
        </ul> -->
        <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Mechanical Calibration</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Electro Technical Calibration</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Thermal Calibration</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Validation Equipment</a>
            </li>
          </ul>
    </div>
    <div class="col-3">
        <p>GOOGLE MAP</p>
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29880.18011314281!2d72.88256294793511!3d20.587138156382427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0dd52ab0f567b%3A0x29274d70bf4cf0da!2sTithal%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1716628464785!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
    </div>
    <div class="col-3">
        <p>KEEP IN TOUCH</p>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Address : B/105, SAMTA APARTMENT NO 2, AGASHI ROAD, VIRAR
              WEST, Palghar, Maharashtra, 401303
              </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Phone : +(91)-8983643660</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Email : proposal@mechtronservices.com
              info@mechtronservices.com</a>
          </li>
        </ul>
    </div>  
</section>

