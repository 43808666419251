
<div class="d-flex h-100 flex-column">
  <div class="header-wrapper flex-shrink-1">
  <!-- The Header Component -->
  <app-header></app-header>
  </div>
  <div class="flex-grow-1 overflow-y-auto">
      <div class="content">
  <!-- This is where the content (home page or other pages) will be displayed -->
  <router-outlet></router-outlet>
      </div>
      <div class="footer">
  <!-- The Header Component -->
  <app-footer></app-footer>
      </div>
  </div>
</div>  
