<section id="shopify-section-template h-100 overflow-h" class="m-0 p-0">
      <section class="b-hero ">
            <div class="container">
                  <article class="b-hero__content  c-text-line-wrapper">



                        <h1 class="text-success fw-bold  text-center">Welcome To Mechtron </h1>
                        <p class="b-hero__subtitle text-white  text-center"><b>MECHTRON-SERVICES</b> is providing
                              Calibration, Validation, Temperature Mapping, HVAC Validation, Instrument Repairing &
                              Servicing, facility to their esteem customer as per the customer’s requirement to have
                              valid <b>NABL ACCREDITATION</b> certification & services within stipulated time and
                              competitive cost.</p>
                        <p class="b-hero__subtitle text-white text-center">Our company is quality conscious, prospering
                              and interested to calibrate instruments for ISO/IES-2017 requirements, energy saving,
                              safety, process control and monitoring, maintaining Quality of products/process, customer
                              requirements, third party audit requirements etc.</p>
                  </article>
            </div>

           
      <section class="b-reasons" id="reasons">
            <div class="content-container">
                  <h2 class="text-primary c-title
          c-title-size--primary
          c-title-color--primary
          
              c-title-align--center
          
          
              b-reasons__title
          ">Our products</h2>
            </div>
      </section>
            <div class="b-hero__slider position-relative">
                  <div class="scroll-container" [@scrollAnimation]>
                        <div class="b-hero__slider-img-wrapper d-flex">
                              <div class="b-hero__slider-img px-4">
                                    <p class="text-center">lab Equipment</p>
                                    <img class="rounded" width="300" height="400" src="../../../assets/images/05.jpg"
                                          alt="Image 1">
                              </div>
                              <div class="b-hero__slider-img px-4">
                                    <p class="text-center">lab Equipment</p>
                                    <img class="rounded" width="300" height="400"
                                          src="../../../assets/images/calibration-services.jpg" alt="Image 2">
                              </div>
                              <div class="b-hero__slider-img px-4">
                                    <p class="text-center">lab Equipment</p>
                                    <img class="rounded" width="300" height="400"
                                          src="../../../assets/images/grey-water-treatment-plants1.jpg" alt="Image 2">
                              </div>
                              <div class="b-hero__slider-img px-4">
                                    <p class="text-center">lab Equipment</p>
                                    <img class="rounded" width="300" height="400"
                                          src="../../../assets/images/water-treatment-plant-process-FEATURE-compressed.jpg" alt="Image 2">
                              </div>
                        </div>

                        <div class="b-hero__shadow"></div>
                  </div>
            </div>
            <!-- s -->
            <section class="b-reasons" id="reasons">
                  <div id="targetId" class="content-container">
                        <h2 class="text-primary mt-3
                c-title-size--primary
                c-title-color--primary
                
                    c-title-align--center
                
                
                    b-reasons__title
                ">About Us</h2>
                  </div>
                  <p class="container ">".Mechtron Services", a Mechtron Group of Company is renowned as a leading company providing Waste Water  Solutions &  Calibration Services of Electro-Mechanical Items.Our Services are described briefly as below .</p>
                  <p class="container">"Mechtron Services" has vast scope for Calibration, Validation, Temperature mapping, HVAC Validation in different areas likeTemperature,ElectricalDimensional,Mass, Time,Noise,Air-Flow, Light & Process control Instruments. The infrastructure in terms of dust free, vibration proof, properly illuminated, temperature and humidity controlled environment in our laboratory with sophisticated master equipment’s, and trained staff manpower give you high level of reliability and confidence. Calibration is conducted in controlled Environment condition as per NABL procedure to achieve accuracy in calibration. </p>
                  <p class="container">We Provide On - Site Calibration, Validation, Temperature mapping, HVAC Validation facility, and also make annually Contracts. We provide Reports (certificate) with attached true copy of our masters having National/International traceability. Our Calibration, Validation, Temperature mapping, HVAC Validation reports are acceptable to all ISO 9000 certifying bodies,multinational companies and govt.tender requirements. We are having reputation in the market for quick delivery, reliability and cheaper rate. Quick response time and delivery as per client requirements. </p>
            </section> 
            
            <section class="b-reasons" id="reasons">
                  <div  class="content-container">
                        <h2 class="text-primary mt-3
                c-title-size--primary
                c-title-color--primary
                
                    c-title-align--center
                
                
                    b-reasons__title
                ">Vison</h2>
                  </div>
                  <p class="container ">Our Vision is to keep improving the quality & Performance of it’s Products and services and to expand Buisness  activity to the global environmental market by providing Sustainable & innovative Solutions .</p>
                  <div class="container m-lg-auto w-50 mt-3">
                        <img src="../../../assets/images/vison.png" alt="">
                  </div>
            </section> 
      </section>



</section>
<div id="shopify-section-template--15981274628254__b0f50cf2-55c8-4c27-bc5b-0bf856f361f6" class="shopify-section">
      <link href="//hoox.co/cdn/shop/t/3/assets/module-reasons.css?v=115676514458557168981687901125" rel="stylesheet"
            type="text/css" media="all">

     


</div>