import { style, transition, trigger ,animate} from '@angular/animations';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  scrollValue:any=5;
  @ViewChild('marquee') el:ElementRef ;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }
}
export const scrollAnimation = trigger('scrollAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('10s linear', style({ transform: 'translateX(-100%)' })),
  ]),
]);
