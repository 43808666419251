<header class="header p-2">
    <div class="header-container">
        <h1 class="header-logo text-success">MECHTRON</h1>
        <ul class="nav">
            <li class="nav-item">
              <a class="nav-link active text-white" aria-current="page" routerLink="/home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#"(click)="scroll('targetId')">About Us</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link text-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <ul class="dropdown-menu dropdown-menu-dark">
                <li><a class="dropdown-item" href="#" >Mechanical Calibratio(click)="scroll('targetId')"n</a></li>
                <li><a class="dropdown-item" href="#">Electro Technical Calibration</a></li>
                <li><a class="dropdown-item" href="#">Thermal Calibration</a></li>
                <li><a class="dropdown-item" href="#">Temperature Mapping & Validation</a></li>

              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white">Enquiry</a>
            </li>
            <li class="nav-item">
                <a href="lol" class="btn btn-success rounded-pill" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@mechtronservices.com" target="_blank">Contact us</a>
              </li>
        </ul>

    </div>

</header>
